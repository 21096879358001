<template>
    <div class="wrap">
        <!-- pc布局 -->
        <b-container class="pc-shop-list">
            <el-row>
                <el-col :span="4">
                    <div class="label-tit"><span class="label cur">{{$t('message.accountinfo')}}</span></div>
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myorder'}">{{$t('message.myorder')}}</router-link></span></div>
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myaddress'}">{{$t('message.addressfrom')}}</router-link></span></div>
                </el-col>
                <el-col :span="20">
                    <div class="pc-shop-group-box">
                        <div class="address-from">
                            <div class="borlef"></div>
                            <div class="title" v-show="userObj.is_company==1">{{$t('message.hi')}}，{{userObjcinfo&&userObjcinfo.company_name}}！</div>
                            <div class="title" v-show="userObj.is_company==0">{{$t('message.hi')}}，{{userObj.family_name}}{{userObj.name}}！</div>
                            <div class="infolist" v-show="userObj.is_company==1">
                                <div class="infoitem">
                                    <div class="name">{{$t('message.companynum')}}：</div>
                                    <div class="info">{{userObjcinfo&&userObjcinfo.ein}}</div>
                                </div>
                                <div class="infoitem">
                                    <div class="name">{{$t('message.companyaddress')}}：</div>
                                    <div class="info">{{userObjcinfo&&userObjcinfo.address}}</div>
                                </div>
                                <div class="infoitem">
                                    <div class="name">{{$t('message.companymobile')}}：</div>
                                    <div class="info">{{userObjcinfo&&userObjcinfo.mobile}}</div>
                                </div>
                                <div class="infoitem">
                                    <div class="name">{{$t('message.companyenum')}}：</div>
                                    <div class="info">{{userObjcinfo&&userObjcinfo.electronic_invoice_number}}</div>
                                </div>
                                <div class="infoitem" v-show="userObj.email">
                                    <div class="name">{{$t('message.email')}}：</div>
                                    <div class="info">{{userObj.email}}</div>
                                </div>
                            </div>
                            <div class="infolist" v-show="userObj.is_company==0">
                                <div class="infoitem">
                                    <div class="name">{{$t('message.surname')}}：</div>
                                    <div class="info">{{userObj.family_name}}</div>
                                </div>
                                <div class="infoitem">
                                    <div class="name">{{$t('message.name')}}：</div>
                                    <div class="info">{{userObj.name}}</div>
                                </div>
                                <div class="infoitem" v-show="userObj.email">
                                    <div class="name">{{$t('message.email')}}：</div>
                                    <div class="info">{{userObj.email}}</div>
                                </div>
                                <div class="infoitem" v-show="userObj.telno">
                                    <div class="name">{{$t('message.phonenum')}}：</div>
                                    <div class="info">{{userObj.telno}}</div>
                                </div>
                                <div class="infoitem">
                                    <div class="name">{{$t('message.country')}}：</div>
                                    <div class="info">{{userObj.nationality}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
        <!-- wap布局 -->
        <b-container class="wap-navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/users' }">{{$t('message.myuser')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.userinfo')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="wap-shop-list">
            <div v-show="userObj.is_company==1">
                <div class="wap-title">{{$t('message.hi')}}，{{userObjcinfo&&userObjcinfo.company_name}}！</div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.companynum')}}：</div>
                    <div class="wap-txt">{{userObjcinfo&&userObjcinfo.ein}}</div>
                </div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.companyaddress')}}：</div>
                    <div class="wap-txt">{{userObjcinfo&&userObjcinfo.address}}</div>
                </div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.companymobile')}}：</div>
                    <div class="wap-txt">{{userObjcinfo&&userObjcinfo.mobile}}</div>
                </div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.companyenum')}}：</div>
                    <div class="wap-txt">{{userObjcinfo&&userObjcinfo.electronic_invoice_number}}</div>
                </div>
                <div class="wap-group" v-show="userObj.email">
                    <div class="wap-label">{{$t('message.email')}}：</div>
                    <div class="wap-txt">{{userObj.email}}</div>
                </div>
            </div>
            <div v-show="userObj.is_company==0">
                <div class="wap-title">{{$t('message.hi')}}，{{userObj.family_name}}{{userObj.name}}！</div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.surname')}}：</div>
                    <div class="wap-txt">{{userObj.family_name}}</div>
                </div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.name')}}：</div>
                    <div class="wap-txt">{{userObj.name}}</div>
                </div>
                <div class="wap-group" v-show="userObj.email">
                    <div class="wap-label">{{$t('message.email')}}：</div>
                    <div class="wap-txt">{{userObj.email}}</div>
                </div>
                <div class="wap-group" v-show="userObj.telno">
                    <div class="wap-label">{{$t('message.phonenum')}}：</div>
                    <div class="wap-txt">{{userObj.telno}}</div>
                </div>
                <div class="wap-group">
                    <div class="wap-label">{{$t('message.country')}}：</div>
                    <div class="wap-txt">{{userObj.nationality}}</div>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {getLocalItem} from '@/untils/common';
export default {
    name:'myself',
    inject:['reload'],
    data(){
        return{
            userObj:{
                // "id":106,
                // "family_name":null,
                // "name":null,
                // "nationality":null,
                // "telno":"18811111111",
                // "email":null,
                // "is_company":1,
                // "company_id":5,
                // "cinfo":{
                //     "address":"测试企业地址",
                //     "ein":"xx123",
                //     "electronic_invoice_number":"12212222222222222222",
                //     "mobile":"18822222222",
                //     "company_name":"测试企业名称",
                //     "salesman_id":6,
                //     "invitation_code":"",
                //     "admin_name":""
                // }
            },
            userObjcinfo:{}
        }
    },
    methods: {
        // 获取用户信息
        getUserInfo(token){
            if(token){
                this.$http.api_user_getinfobytoken({
                    token:token
                }).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.userObj = res.data;
                                this.userObjcinfo = res.data.cinfo
                                if(res.data.is_company==1){
                                    if(!res.data.cinfo.company_name){
                                        this.$router.push({path:'/uinfo',query:{type:3}});
                                    }
                                }else{
                                    if(!res.data.family_name){
                                        this.$router.push({path:'/uinfo',query:{type:1}});
                                    }
                                }
                            }
                        }else if(res.code==-1){
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }
        }
    },
    created(){
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            this.getUserInfo(token);// 获取用户信息
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>

/deep/a{
    color:#616166 ;
    text-decoration: none;
}
.wap-navtab{
    padding:20px 10px 10px;
    
}
.clear{
    clear: both;
}
.navtab{
    padding:20px 10px 0;
    
}
.pc-shop-list{
    padding:20px 10px 0;
    .label-tit{
        padding:20px 0 0;
        font-size:16px;
        .label{
            cursor: pointer;
            display: inline-block;
            
            color: #616166;
            margin: 0 10px;
            padding:0 10px 0 20px;
            vertical-align: middle;
        }
        .cur{
            color: #1F73B7;
            border-left:2px solid #1F73B7;
        }
    }
}
.pc-shop-group-box{
    padding:20px 10px 0;
    .address-from{
        padding:0 300px 0 70px;
        position: relative;
        .borlef{
            height:270px;
            position: absolute;
            left: 0;
            top:0;
            border-left: 1px solid #eff2f9;
        }
        .title{
            font-size: 24px;
            padding-bottom: 10px;
        }
        .infolist{
            padding:20px 0;
            .infoitem{
                display: flex;
                -webkit-box-orient: horizontal;
                padding:10px 0;
                .name{
                    color: #95959e;
                    width:100px;
                    
                }
                .info{
                    color: #616166;
                    
                }
                
            }
        }
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
        .address-from{
            padding:0 100px 0 70px;
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            padding:0 10px;
            .wap-title{
                width: 100%;
                font-size:26px;
                padding: 20px 0 10px;
            }
            .wap-group{
                display: flex;
                -webkit-box-orient: horizontal;
                width: 100%;
                padding:10px 0;
                .wap-label{
                    width:30%;
                    color: #95959E;
                }
                .wap-txt{
                    width: 70%;
                    color: #616166;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            padding:0 10px;
            .wap-title{
                width: 100%;
                font-size:26px;
                padding: 20px 0 10px;
            }
            .wap-group{
                display: flex;
                -webkit-box-orient: horizontal;
                width: 100%;
                padding:10px 0;
                .wap-label{
                    width:30%;
                    color: #95959E;
                }
                .wap-txt{
                    width: 70%;
                    color: #616166;
                }
            }
        }
    }
    
}
</style>